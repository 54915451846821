import React, { useState, useEffect, useRef } from 'react';
import {
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    Select,
    Text,
    Button,
    Textarea,
    VStack,
    Box,
    FormControl,
    FormLabel,
    SimpleGrid,
    HStack,
    useToast,
    useColorModeValue,
    Heading,
    StackDivider,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    Stack,
    Spacer,
    Flex,
    Spinner,
} from '@chakra-ui/react';
import WaitlistAPI, { Payload } from './API';
import { TableRow } from './EXPwaitlist';

interface DriverDetailDrawerProps {
    isOpen: boolean;
    onClose: () => void;
    driverData: {
        id: string;
        data: {
            ID: number;
            DriverName: string;
            TruckNumber: string;
            DriverCity: string;
            DriverState: string;
            Division: string;
            StatusChangeType: string;
            Status: string;
            FirstAvailable: string;
            PreferredAccount: string;
            CostCenter: string;
            DriverType: string;
            DriverEmail: string;
            CompletedDate: string;
            HireDate: string;
            Note?: string;
            RejectionReason?: string;
            RejectionNote?: string;
            WaitlistStatusTimestamp: string;
            DriverLeaderName: string;
            DriverLeaderEmail: string;
            AdminNote?: string;
            UniqueID: string;
            Shift?: string; 
        };
    } | null;
    fetchData: () => void;
    rowData: TableRow | null;
    email: string;
    name: string;
}

interface Note {
    ID: number;
    MasterID: number;
    Created: string;
    Note: string;
    UserEmail: string | null;
    FullName: string | null;
}

const DriverDetailDrawer: React.FC<DriverDetailDrawerProps> = ({ isOpen, onClose, driverData, fetchData, email, name  }) => {
    const [accounts, setAccounts] = useState<string[]>([]);
    const [notes, setNotes] = useState<Note[]>([]);
    const [error, setError] = useState<string>('');
    const [note, setNote] = useState<string>('');
    const [isRejectAlertOpen, setIsRejectAlertOpen] = useState(false);
    const [rejectionReason, setRejectionReason] = useState<string>('');
    const [rejectionComment, setRejectionComment] = useState<string>('');
    const [isConfirmRejectDisabled, setIsConfirmRejectDisabled] = useState(true);
    const [isConfirmAccountDialogOpen, setIsConfirmAccountDialogOpen] = useState(false);
    const toast = useToast();
    const [preferredAccount, setPreferredAccount] = useState<string>('');
    const [isLoading, setIsLoading] = useState(false);
    const cancelRef = useRef<HTMLButtonElement | null>(null);

    // Border color based on color mode
    const borderColor = useColorModeValue('gray.200', 'gray.600');

    // Function to close the confirmation dialog
    const closeConfirmAccountDialog = () => {
        setIsConfirmAccountDialogOpen(false);
    };

    const handleAccountSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setPreferredAccount(event.target.value);
    };

    const handleConfirmAccountDialogOpen = () => {
        if (!preferredAccount) {
            toast({
                title: 'Action Required',
                description: 'Please choose a Preferred Account before confirming.',
                status: 'warning',
                duration: 5000,
                isClosable: true,
            });
        } else {
            setIsConfirmAccountDialogOpen(true);
        }
    };

    //convert to local time
    const formatLocalDateTime = (utcDateTime: string): string => {
        const date = new Date(utcDateTime);
        return date.toLocaleString('en-US', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: true
        });
    };

    //Convert to date only
    const formatDate = (isoDateTime: string): string => {
        const date = new Date(isoDateTime);
        return date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        });
    };


    // Define fetchNotes at the top level of your component
    const fetchNotes = async () => {
        if (!driverData || !driverData.id) return;

        try {
            const payload: Payload = { process: 'EXPfetchNotes', driverId: driverData.id };
            const response = await WaitlistAPI(payload);

            if (response && response.data && response.data.ResultSets && response.data.ResultSets.Table1) {
                const fetchedNotes: Note[] = response.data.ResultSets.Table1;
                setNotes(fetchedNotes);
            } else {
                setError('No notes available');
                setNotes([]);
            }
        } catch (error) {
            setError('Failed to fetch notes');
        }
    };

    // Fetch accounts data from API
    useEffect(() => {
        const fetchAccounts = async () => {
            try {
                const response = await WaitlistAPI({ process: 'fetchAccounts' });
                if (response && response.data && response.data.ResultSets && response.data.ResultSets.Table1) {
                    const accountDescriptions = response.data.ResultSets.Table1.map((account: any) => account.Cost_Center_Description);
                    setAccounts(accountDescriptions);
                } else {
                    setError('No accounts data available');
                    setAccounts([]);
                }
            } catch (error) {
                setError('Failed to fetch accounts');
            }
        };

        if (isOpen) {
            fetchAccounts();
            fetchNotes();
        }
    }, [isOpen, driverData]);

    // Handle reject action
    const handleReject = () => {
        setIsRejectAlertOpen(true);
    };

    // Confirm that the handleConfirmReject function is called when the Confirm button in the rejection dialog is clicked
    const handleConfirmReject = async (driverId: number) => {
        setIsLoading(true); 
        // Check if rejection reason and comment are provided
        if (!rejectionReason || !rejectionComment) {
            toast({
                title: 'Missing Information',
                description: 'Please provide both the rejection reason and comment.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
            setIsLoading(false);  
            return;
        }
    
        try {
            // Construct payload with rejection reason, comment, and driver ID
            const payload: Payload = {
                process: 'EXPReject',
                id: driverId,
                rejectionReason: rejectionReason,
                rejectionComment: rejectionComment,
                email: email
            };
    
            // Make API call to reject the driver
            await WaitlistAPI(payload);
            toast({
                title: 'Driver Rejected',
                description: 'The driver has been rejected successfully.',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
            setIsRejectAlertOpen(false);  
            onClose(); 
            fetchData(); 
            setIsLoading(false); 
        } catch (error) {
            toast({
                title: 'Error',
                description: 'Failed to reject the driver. Please try again later.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        } 
    };
    

    // Cancel reject action
    const handleCancelReject = () => {
        setIsRejectAlertOpen(false);
    };


    // Save Note function
    const handleSaveNote = async () => {
        setIsLoading(true);
        if (!driverData || !driverData.data || !driverData.data.ID) return;

        const payload: Payload = { process: 'EXPCreateNote', driverId: driverData.data.ID, note: note, email, name};
        try {
            await WaitlistAPI(payload);
            setNote('');
            fetchNotes();

            toast({
                title: 'Note saved',
                description: "The note has been successfully saved.",
                status: 'success',
                duration: 5000,
                isClosable: true,
                position: "top"
            });
        } catch (error) {
        } finally {
            setIsLoading(false);
        }
    };

    // Function API call to submit the preferred account
    const handleSubmitPreferred = async (preferredAccount: string, rowId: string) => {
        setIsLoading(true);
        try {
            await WaitlistAPI({
                process: 'EXPSubmitPreferredfromAvailable',
                preferredAccount: preferredAccount,
                id: rowId,
                email: email
            });

            toast({
                title: 'Preferred Account Submitted',
                description: 'The preferred account has been submitted successfully.',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
            onClose();
            fetchData()
        } catch (error) {
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="xl" >
            <DrawerOverlay />
            <DrawerContent >

                <DrawerCloseButton />
                <DrawerHeader borderBottomWidth="1px">EXP Waitlist</DrawerHeader>
                <DrawerBody >
                    {isLoading ? (
                        // Display loading spinner taking up the whole drawer size
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100%',
                            }}
                        >
                            <Spinner />
                        </div>
                    ) : (
                        // Display driver details, control center, and notes
                        <>
                            {driverData ? (
                                <VStack spacing={6} align="stretch" >
                                    <Box borderWidth="1px" borderRadius="lg" borderColor={borderColor} p={4} boxShadow="md" >
                                        <Heading size="sm" mb={4} color="blue.900">Driver Details</Heading>
                                        <SimpleGrid columns={3} spacing={4}>
                                            <FormControl>
                                                <FormLabel>Name:</FormLabel>
                                                <Text>{driverData.data.DriverName}</Text>
                                            </FormControl>
                                            <FormControl>
                                                <FormLabel>City:</FormLabel>
                                                <Text>{driverData.data.DriverCity}</Text>
                                            </FormControl>
                                            <FormControl>
                                                <FormLabel>State:</FormLabel>
                                                <Text>{driverData.data.DriverState}</Text>
                                            </FormControl>
                                            <FormControl>
                                                <FormLabel>Division:</FormLabel>
                                                <Text>{driverData.data.Division}</Text>
                                            </FormControl>
                                            <FormControl>
                                                <FormLabel>Type:</FormLabel>
                                                <Text>{driverData.data.StatusChangeType}</Text>
                                            </FormControl>
                                            <FormControl>
                                                <FormLabel>Truck Number:</FormLabel>
                                                <Text>{driverData.data.TruckNumber}</Text>
                                            </FormControl>
                                            <FormControl>
                                                <FormLabel>Cost Center:</FormLabel>
                                                <Text>{driverData.data.CostCenter}</Text>
                                            </FormControl>
                                            <FormControl>
                                                <FormLabel>Hire Date:</FormLabel>
                                                <Text>{formatDate(driverData.data.HireDate)}</Text>
                                            </FormControl>
                                            <FormControl>
                                                <FormLabel>Leader Name:</FormLabel>
                                                <Text>{driverData.data.DriverLeaderName}</Text>
                                            </FormControl>
                                            <FormControl>
                                                <FormLabel>Reason:</FormLabel>
                                                <Text>{driverData.data.Note}</Text>
                                            </FormControl>
                                            <FormControl>
                                                <FormLabel>Preferred Shift:</FormLabel>
                                                <Text>{driverData.data.Shift}</Text>
                                            </FormControl>
                                        </SimpleGrid>
                                    </Box>
                                    <Box p={4} borderWidth="1px" borderRadius="lg" boxShadow="md" mt={4}>
                                        <Heading size="sm" mb={4} color="blue.900">Control Center</Heading>
                                        <FormControl id="account-selection" mb={4}>
                                            <Select
                                                placeholder="Select Preferred Account"
                                                value={preferredAccount}
                                                onChange={handleAccountSelectChange}
                                            >
                                                {accounts.map((account, index) => (
                                                    <option key={index} value={account}>{account}</option>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        <HStack spacing={4} mt={4} justifyContent="flex-end">
                                            <Spacer />
                                            <Button
                                                size="sm"
                                                variant="outline"
                                                colorScheme="red"
                                                onClick={handleReject}
                                                boxShadow="sm"
                                                borderColor="red.500"
                                            >
                                                Reject
                                            </Button>
                                            <Button
                                                size="sm"
                                                variant="outline"
                                                colorScheme="green"
                                                onClick={handleConfirmAccountDialogOpen}
                                                isDisabled={!preferredAccount}
                                                boxShadow="sm"
                                                borderColor="green.500"
                                            >
                                                Confirm Account
                                            </Button>
                                        </HStack>
                                    </Box>
                                    <Box p={4} borderWidth="1px" borderRadius="lg" boxShadow="md" mt={4}>
                                        <Heading size="sm" mb={4} color="blue.900">Notes</Heading>
                                        <VStack divider={<StackDivider borderColor="gray.200" />} spacing={4} align="stretch">
                                            <FormControl id="add-note">
                                                <Textarea
                                                    placeholder="Enter note here..."
                                                    value={note}
                                                    onChange={(e) => setNote(e.target.value)}
                                                    size="sm"
                                                    bg="white"
                                                    borderColor="gray.300"
                                                    boxShadow="inner"
                                                />
                                                <Flex justifyContent="flex-end" mt={3}>
                                                    <Button
                                                        size="xs"
                                                        variant="outline"
                                                        colorScheme="blue"
                                                        onClick={handleSaveNote}
                                                        boxShadow="sm"
                                                        borderColor="blue.500"
                                                    >
                                                        Save
                                                    </Button>
                                                </Flex>
                                            </FormControl>
                                            {notes.length > 0 ? (
                                                notes.map((note, index) => (
                                                    <Box key={index} p={4} borderWidth="1px" borderRadius="md" boxShadow="md" bg="gray.100">
                                                    <Text fontWeight="normal">{note.Note}</Text>
                                                    <Flex justifyContent="flex-end" alignItems="flex-end" direction="column" mt={2}>
                                                      <Text fontSize="sm" color="gray.500" alignSelf="flex-end">{formatLocalDateTime(note.Created)}</Text>
                                                      <Text fontWeight="medium" color="gray.800" fontSize="sm" alignSelf="flex-end">{note.FullName}</Text>
                                                    </Flex>
                                                  </Box>
                                                  
                                                  
                                                
                                                ))
                                            ) : (
                                                <Text color="gray.100">No notes available.</Text>
                                            )}
                                        </VStack>
                                    </Box>
                                </VStack>
                            ) : (
                                // No data available message
                                <Text>No data available.</Text>
                            )}
                        </>
                    )}
                </DrawerBody>
            </DrawerContent>

            {/* Reject Alert Dialog */}
            <AlertDialog
                isOpen={isRejectAlertOpen}
                leastDestructiveRef={cancelRef}
                onClose={handleCancelReject}
                isCentered
            >
                <AlertDialogOverlay />
                <AlertDialogContent borderRadius="md" boxShadow="lg" bg="white">
                    <AlertDialogHeader fontSize="lg" fontWeight="bold" textAlign="center" color="gray.800">
                        Reject
                    </AlertDialogHeader>
                    <AlertDialogBody textAlign="center">
                        Are you sure you want to reject?
                        <Stack spacing={4} mt="2">
                            <FormControl isRequired>

                                <FormLabel color="gray.600">Rejection Reason</FormLabel>
                                <Select
                                    placeholder="Select a reason"
                                    value={rejectionReason}
                                    onChange={(e) => setRejectionReason(e.target.value)}
                                    borderRadius="md"
                                    borderColor="gray.300"
                                    focusBorderColor="blue.500"
                                >
                                    <option value="Multiple Service Fails">Multiple Service Fails</option>
                                    <option value="Multiple Safety Violations">Multiple Safety Violations</option>
                                    <option value="Does Not Meet Criteria">Does Not Meet Criteria</option>
                                </Select>
                            </FormControl>
                            <FormControl isRequired>

                                <FormLabel color="gray.600">Rejection Note</FormLabel>
                                <Textarea
                                    value={rejectionComment}
                                    onChange={(e) => setRejectionComment(e.target.value)}
                                    placeholder="Enter rejection notes"
                                    borderRadius="md"
                                    borderColor="gray.300"
                                    focusBorderColor="blue.500"
                                    minHeight="100px"
                                />
                            </FormControl>
                        </Stack>
                    </AlertDialogBody>
                    <AlertDialogFooter justifyContent="center">
                        <HStack spacing={40}>
                            <Button ref={cancelRef} onClick={handleCancelReject} variant="outline">
                                Cancel
                            </Button>
                            <Button
                                colorScheme="red"
                                onClick={() => handleConfirmReject(driverData?.data.ID || 0)}
                                disabled={isConfirmRejectDisabled}
                                borderRadius="md"
                                px={6}
                                _hover={{ bg: 'red.500' }}
                            >
                                Confirm
                            </Button>
                        </HStack>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>


            {/* Confirmation Dialog */}
            <AlertDialog
                isOpen={isConfirmAccountDialogOpen}
                leastDestructiveRef={cancelRef}
                onClose={closeConfirmAccountDialog}
                isCentered  // Ensure the dialog is centered
            >
                <AlertDialogOverlay />
                <AlertDialogContent borderRadius="md" boxShadow="lg" bg="white">
                    <AlertDialogHeader fontSize="lg" fontWeight="bold" textAlign="center" color="gray.800">
                        Confirm Account Selection
                    </AlertDialogHeader>
                    <AlertDialogBody>
                        Are you sure you want to confirm this account selection?
                    </AlertDialogBody>
                    <AlertDialogFooter justifyContent="center" mt={4}>
                        <HStack spacing={40}>
                            <Button ref={cancelRef} onClick={closeConfirmAccountDialog} variant="outline">
                                Cancel
                            </Button>
                            <Button
                                colorScheme="green"
                                onClick={() => {
                                    handleSubmitPreferred(preferredAccount, driverData?.data.ID?.toString() || '');
                                    closeConfirmAccountDialog();
                                }}
                                borderRadius="md"
                                px={6}
                                _hover={{ bg: 'green.500' }}
                            >
                                Confirm
                            </Button>
                        </HStack>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>

        </Drawer>



    );
};

export default DriverDetailDrawer;


