import { ChakraProvider, theme } from "@chakra-ui/react";
import * as ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';


//MSAL Import
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";

const msalInstance = new PublicClientApplication(msalConfig);


// const msalInstance = new PublicClientApplication(msalConfig);
const container = document.getElementById("root");
if (!container) throw new Error("Failed to find the root element");
const root = ReactDOM.createRoot(container);

root.render(
  <MsalProvider instance={msalInstance}>
  <ChakraProvider theme={theme}>
    <BrowserRouter>
    
        <App />
 
     
    </BrowserRouter>
  </ChakraProvider>
  </MsalProvider>

);
