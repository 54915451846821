import axios from "axios";

export interface Payload {
  [key: string]: any;
}

const WaitlistAPI = async (payload: Payload) => {
  const url = 'https://prod-56.eastus.logic.azure.com:443/workflows/b6996ea97b9c4eb9bae89785e24db44b/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=XiryhsJN9Ud_A53lWjlgeP39XLcrAyuui9ClKjvY4Vg';

  

  const config = {
    headers: {
    },
  };

  try {
    const response = await axios.post(url, payload, config);
    return response;

  } catch (error) {
    console.error("API request failed:", error);
  }
};


export default WaitlistAPI;
