import { LogLevel } from "@azure/msal-browser";

const Redirect = 'https://service.hirschbach.com'

export const msalConfig = {
  auth: {
    clientId: "833c0ce9-ca05-4ab6-88c0-ffe82fe9d158",
    authority:
      "https://login.microsoftonline.com/33a0f4d4-f8f3-481c-bdeb-9868b68e1596",
    redirectUri: Redirect,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  },
};

export const graphConfig = {
  graphMeEndpoint:
    "https://graph.microsoft.com/v1.0/me?$select=displayName,mail,jobTitle,department,userPrincipalName,photo",
  graphUserPhotoEndpoint: "https://graph.microsoft.com/v1.0/me/photo/$value",
};


export const graphScopes = [
  "https://graph.microsoft.com/User.Read",
  "https://graph.microsoft.com/User.ReadBasic.All",
];


