import React, { useRef, useState, useEffect } from 'react';
import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
  FormControl,
  FormLabel,
  Select,
  Textarea,
  Stack,
  HStack,
  Box,
} from '@chakra-ui/react';

interface ConfirmDialogProps {
  isOpen: boolean; 
  onClose: () => void; 
  onConfirm: () => void; 
  confirmType: 'approve' | 'reject' | 'complete' | 'moveback' | 'cancel'; 
  rejectionReason: string; 
  setRejectionReason: (reason: string) => void; 
  rejectionNote: string; 
  setRejectionNote: (note: string) => void; 
}

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  isOpen,
  onClose,
  onConfirm,
  confirmType,
  rejectionReason,
  setRejectionReason,
  rejectionNote,
  setRejectionNote,
}) => {
  const cancelRef = useRef<HTMLButtonElement>(null);

  // Define action and header labels based on confirmType
  const actionLabelMap = {
    approve: 'Approve',
    reject: 'Reject',
    moveback: 'Move Back',
    complete: 'Complete',
    cancel: 'Cancel Approval',
  };

  const headerLabelMap = {
    approve: 'Approve',
    reject: 'Reject',
    moveback: 'Moveback to First Available',
    complete: 'Complete',
    cancel: 'Cancel Approval',
  };

  const actionLabel = actionLabelMap[confirmType];
  const headerLabel = headerLabelMap[confirmType];

  // Determine if the confirm button should be disabled
  const isConfirmDisabled = (confirmType === 'reject' && (!rejectionReason || !rejectionNote));

  // Function to reset rejection reason and note
  const resetRejectionFields = () => {
    setRejectionReason('');
    setRejectionNote('');
  };

  return (
    <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose} isCentered>
      <AlertDialogOverlay />
      <AlertDialogContent borderRadius="md" boxShadow="lg" bg="white">
        <AlertDialogHeader fontSize="lg" fontWeight="bold" textAlign="center" color="gray.800">
          {headerLabel}
        </AlertDialogHeader>
        <AlertDialogBody textAlign="center">
          Are you sure you want to {actionLabel}?
          {confirmType === 'reject' && (
            <Stack spacing={4} mt={4}>
              <FormControl>
                <FormLabel color="gray.600">
                  Rejection Reason <Box as="span" color="red">*</Box>
                </FormLabel>
                <Select
                  placeholder="Select a reason"
                  value={rejectionReason}
                  onChange={(e) => setRejectionReason(e.target.value)}
                  borderRadius="md"
                  borderColor="gray.300"
                  focusBorderColor="blue.500"
                >
                  <option value="Multiple Service Fails">Multiple Service Fails</option>
                  <option value="Multiple Safety Violations">Multiple Safety Violations</option>
                  <option value="Does Not Meet Criteria">Does Not Meet Criteria</option>
                </Select>
              </FormControl>
              <FormControl>
                <FormLabel color="gray.600">
                  Rejection Note <Box as="span" color="red">*</Box>
                </FormLabel>
                <Textarea
                  value={rejectionNote}
                  onChange={(e) => setRejectionNote(e.target.value)}
                  placeholder="Enter rejection notes"
                  borderRadius="md"
                  borderColor="gray.300"
                  focusBorderColor="blue.500"
                  minHeight="100px"
                />
              </FormControl>
            </Stack>
          )}
        </AlertDialogBody>
        <AlertDialogFooter justifyContent="center" mt={4}>
          <HStack spacing={40}>
            <Button ref={cancelRef} onClick={onClose} variant="outline">
              Cancel
            </Button>
            <Button
              colorScheme={confirmType === 'complete' ||confirmType === 'moveback' || confirmType === 'approve' ? 'green' : confirmType === 'cancel' ? 'red' : 'red'}
              onClick={() => {
                onConfirm();
                if (confirmType === 'reject') {
                  resetRejectionFields();
                }
              }}
              disabled={isConfirmDisabled}
              borderRadius="md"
              px={6}
              _hover={{
                bg: confirmType === 'complete' || confirmType === 'moveback' || confirmType === 'approve' ? 'green.500' : confirmType === 'cancel' ? 'red.500' : 'red.500',
              }}
            >
              Confirm
            </Button>
          </HStack>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export { ConfirmDialog };
