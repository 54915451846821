import { Routes, Route } from "react-router-dom";
import EXPwaitlist from "./EXPWaitlist/EXPwaitlist";
import TopBar from "./TopBar";

// MSAL & authentication imports
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { graphScopes } from "./authConfig";
import { callMsGraph, getUserProfilePhoto } from "./graph";
import { useEffect, useState } from "react";


const App = () => {

  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userDepartment, setUserDepartment] = useState("");

  const { instance, accounts, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  // Set user data from Graph API response
  const setUserData = (graphResponse: any) => {
    setUserName(graphResponse.displayName);
    setUserDepartment(graphResponse.department);
    setUserEmail(graphResponse.mail || graphResponse.userPrincipalName);
  };

  // Fetch user data from Graph API if authenticated
  const fetchProfileData = async () => {
    try {
      const graphTokenResponse = await instance.acquireTokenSilent({
        scopes: graphScopes,
        account: accounts[0],
      });

      const graphResponse = await callMsGraph(graphTokenResponse.accessToken);
      setUserData(graphResponse);

      const userProfilePhoto = await getUserProfilePhoto(
        graphTokenResponse.accessToken
      );


    } catch (error) {
      console.error("Error:", error);
    }
  };



  // Check if user is authenticated and redirect to login
  useEffect(() => {
    const checkAuthAndRedirect = async () => {
      try {
        if (inProgress === "none" && !isAuthenticated && !accounts.length) {
          await instance.loginRedirect({ scopes: graphScopes });
        } else if (isAuthenticated && accounts.length) {
          fetchProfileData();

        }
      } catch (error) {
        console.error("Login error:", error);
      }
    };

    checkAuthAndRedirect();
  }, [isAuthenticated, instance, inProgress]);


  return (
    <div
      style={{
        background: "linear-gradient(to right, #f8f9fa, #f1f1f1)",
        height: "100vh",
      }}
    >
      <TopBar
        userName={userName}
        userEmail={userEmail}
        userPhoto={null}
      />
      <Routes>
        <Route path="/" element={<EXPwaitlist userName={userName} userEmail={userEmail} userDepartment={userDepartment} />} />

      </Routes>
    </div>
  );
};

export default App;
