import React, { useState, useEffect, useRef } from 'react';
import {
  Box, Flex, Heading, Table, Tbody, Tr, Td, Center, IconButton, useToast, Text, 
  Spinner
} from '@chakra-ui/react';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import { FaThumbsUp, FaThumbsDown, FaCheck, FaInfoCircle, FaTimes } from 'react-icons/fa';
import WaitlistAPI, { Payload } from './API';
import EXPfirstAvailable from './EXPfirstAvailable';
import { ConfirmDialog } from './EXPconfirmDialog';
import { ArrowBackIcon } from '@chakra-ui/icons';
import DriverDetailDrawer from './EXPDriverDetailDrawer';

// Define TableRow type
export interface TableRow {
  id: string;
  data: {
    ID: number;
    DriverName: string;
    DriverCode: string;
    TruckNumber: string;
    DriverCity: string;
    DriverState: string;
    Division: string;
    StatusChangeType: string;
    Status: string;
    PreferredAccount: string;
    FirstAvailable: string;
    CostCenter: string;
    DriverType: string;
    DriverEmail: string;
    CompletedDate: string;
    HireDate: string;
    RejectionReason?: string;
    RejectionNote?: string;
    WaitlistStatusTimestamp: string;
    DriverLeaderName: string;
    DriverLeaderEmail: string;
    AdminNote?: string;
    UniqueID: string;
    Modified?: string;
    Created?: string;
    CreatedBy?: string;
    ModifiedBy?: string;
    Requester?: string;
    Note?: string;
    Approver?: string;
    Rank?: string;
    Shift?: string;
  };
}

interface TableData {
  [accountId: string]: TableRow[];
}

interface ApiResponse {
  data: {
    ResultSets: {
      Table1: any[];
    };
  };
}

interface Props {
  userName: string;
  userEmail: string;
  userDepartment: string;
}

const EXPwaitlist: React.FC<Props> = ({ userName, userEmail, userDepartment }) => {
  const [rightSectionTables, setRightSectionTables] = useState<TableData>({});
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [confirmType, setConfirmType] = useState<'approve' | 'reject' | 'moveback' | 'cancel' | 'complete'>('approve');
  const [rowId, setRowId] = useState<string>('');
  const [rejectionReason, setRejectionReason] = useState('');
  const [rejectionNote, setRejectionNote] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState<TableRow | null>(null);
  const toCamelCase = (str: string) => {
    return str.toLowerCase().replace(/(^|\s)\S/g, (match) => match.toUpperCase());
  };


  //Function to Retieve all Data from EXP Waitlist
  const fetchData = async () => {
    const payload: Payload = { process: 'DEData' };
    const response = await WaitlistAPI(payload);
    // console.log("Original data from API:", response);
    if (!response || !response.data || !response.data.ResultSets || !response.data.ResultSets.Table1) {
      return;
    }
    const apiResponse: ApiResponse = response;
    const allRows: TableRow[] = apiResponse.data.ResultSets.Table1.map((item) => ({
      id: item.ID.toString(),
      data: {
        ID: item.ID,
        DriverName: item.Driver_x0020_Name,
        DriverCode: item.Driver_x0020_Code,
        TruckNumber: item.Truck_x0020_Number,
        DriverCity: item.Driver_x0020_City,
        DriverState: item.Driver_x0020_State,
        Division: item.Division,
        StatusChangeType: item.Status_x0020_Change_x0020_Type,
        Status: item.Status,
        PreferredAccount: item.Preferred_x0020_Account,
        FirstAvailable: item.First_x0020_Available_x0020_Account,
        CostCenter: item.Cost_x0020_Center,
        DriverType: item.Driver_x0020_Type,
        DriverEmail: item.Driver_x0020_Email,
        CompletedDate: item.Completed_x0020_Date,
        HireDate: item.Hire_x0020_Date,
        RejectionReason: item.Rejection_x0020_Reason,
        RejectionNote: item.Rejection_x0020_Note,
        WaitlistStatusTimestamp: item.Waitlist_x0020_Status_x0020_Timestamp,
        DriverLeaderName: item.Driver_x0020_Leader_x0020_Name,
        DriverLeaderEmail: item.Driver_x0020_Leader_x0020_Email,
        AdminNote: item.Admin_x0020_Note,
        UniqueID: item.UniqueID,
        Modified: item.Modified,
        Created: item.Created,
        CreatedBy: item.CreatedBy,
        ModifiedBy: item.ModifiedBy,
        Requester: item.Requester,
        Note: item.Note,
        Approver: item.Approver,
        Rank: item.Rank,
        Shift: item.Shift,
      },
    }));

    // Sort the rows by Rank
    allRows.sort((a, b) => {
      const rankA = a.data.Rank != null ? Number(a.data.Rank) : Number.MAX_VALUE;
      const rankB = b.data.Rank != null ? Number(b.data.Rank) : Number.MAX_VALUE;
      // arithmetic operation
      return rankA - rankB;
    });

    const tablesData: TableData = {};
    allRows.forEach((row) => {
      const accountKey = row.data.PreferredAccount || 'No Account Specified';
      if (!tablesData[accountKey]) {
        tablesData[accountKey] = [];
      }
      tablesData[accountKey].push(row);
    });
    setRightSectionTables(tablesData);
  };


  //fetch data on a 30 sec timer
  useEffect(() => {
    fetchData();
    const timer = setInterval(fetchData, 50000);
    return () => clearInterval(timer);
  }, []);

  // Function to log currently visible data in Right Section Tables
  const logVisibleRightSectionTablesData = () => {
    Object.entries(rightSectionTables)
      .filter(([accountId, rows]) => {
        // Filter out any accounts with no rows or completed/rejected rows
        const hasNonEmptyPreferredAccount = rows.some(row => row.data.PreferredAccount !== 'None');
        const hasNonCompletedOrRejectedRows = rows.some(row => row.data.Status !== 'Completed' && row.data.Status !== 'Rejected');
        return accountId !== 'None' && hasNonEmptyPreferredAccount && hasNonCompletedOrRejectedRows;
      })
      .forEach(([accountId, rows], index) => {
        // console.log(`Account: ${accountId}`);
        rows.forEach((row, index) => {
          // console.log(`Index: ${index + 1}, ID: ${row.data.ID}, Driver Code: ${row.data.DriverCode}, Preferred Account: ${row.data.PreferredAccount}`);
        });
      });
  };

  //  function to log the data
  useEffect(() => {
    fetchData().then(() => {
      logVisibleRightSectionTablesData();
    });
  }, []);

  //This will determine what to display on the alert dialog
  const openDialog = (type: 'approve' | 'reject' | 'complete' | 'moveback' | 'cancel', rowId: string) => {
    setConfirmType(type);
    setRowId(rowId);
    setDialogOpen(true);
  };

  //Closing the alert dialog
  const closeDialog = () => {
    setDialogOpen(false);
  };

  // Main function to handle dragging the rows
  const onDragEnd = async (result: DropResult) => {
    const { destination, source } = result;
    if (!destination) {
      return;
    }
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }
    const sourceRows = rightSectionTables[source.droppableId];
    const destinationRows = rightSectionTables[destination.droppableId];
    // Remove the row from the source list
    const [removed] = sourceRows.splice(source.index, 1);
    // If the destination is different, update the preferredAccount to the new table's accountId
    if (destination.droppableId !== source.droppableId) {
      removed.data.PreferredAccount = destination.droppableId;
    }
    // Insert the row into the destination list
    destinationRows.splice(destination.index, 0, removed);
    // Update the state with the new lists
    setRightSectionTables(prevTables => ({
      ...prevTables,
      [source.droppableId]: sourceRows,
      [destination.droppableId]: destinationRows
    }));
    // Construct the payload for all tables since the order and the table may have changed
    const updatedRowsPayload = Object.entries(rightSectionTables).flatMap(([accountId, rows]) =>
      rows.map((row, index) => ({
        id: row.id,
        preferredAccount: accountId,
        newIndex: index + 1, // Increment the index by 1 to start from 1
        driverCode: row.data.DriverCode
      }))
    );
    // Call the API to update the table data
    const payload: Payload = {
      process: 'EXPUpdateTableData',
      email: userEmail,
      updatedRows: updatedRowsPayload
    };
    setIsLoading(true);
    try {
      const response = await WaitlistAPI(payload);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
    // Log the visible data from right section tables after drag and drop
    logVisibleRightSectionTablesData();
  };

  // function to handle completing the row. 
  const handleCompleteConfirm = async () => {
    setIsLoading(true);
    closeDialog();
    const payload: Payload = {
      process: 'EXPcomplete',
      email: userEmail,
      id: rowId
    };
    try {
      const response = await WaitlistAPI(payload);
      toast({
        title: 'Completion Confirmed',
        description: 'The completion has been confirmed successfully.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      fetchData();
      setIsLoading(false);

    } catch (error) {
    } finally {

    }
  };

  //Function to handle Approving the row (not completing)
  const handleConfirm = async () => {
    setIsLoading(true);
    closeDialog();
    const payload: Payload = {
      process: 'EXPConfirmApproval',
      id: rowId,
      email: userEmail
    };
    try {
      const response = await WaitlistAPI(payload);
      toast({
        title: 'Approval Confirmed',
        description: 'The approval has been confirmed successfully.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      fetchData();
      closeDialog();
      setIsLoading(false);

    } catch (error) {
    }
  };

  //Function for moving row back to First Available
  const handleMoveBack = async () => {
    setIsLoading(true);
    const payload: Payload = {
      process: 'EXPmoveback',
      id: rowId,
      email: userEmail
    };
    try {
      const response = await WaitlistAPI(payload);
      toast({
        title: 'Move Back Confirmed',
        description: 'The move back action has been confirmed successfully.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      // Refetch data after move back confirmation
      fetchData();
      closeDialog();
    } catch (error) {
      toast({
        title: 'Error',
        description: 'An error occurred while confirming move back. Please try again later.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  //function to cancel approval and revert back to "Pending"
  const handleCancelApproval = async () => {
    setIsLoading(true);
    const payload = {
      process: 'EXPcancelApprove',
      id: rowId,
      email: userEmail
    };

    try {
      const response = await WaitlistAPI(payload);
      toast({
        title: 'Cancellation Confirmed',
        description: 'The approval cancellation has been confirmed successfully.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      fetchData(); // Refetch the data to update the table
      closeDialog();
      setIsLoading(false);
    } catch (error) {
      toast({
        title: 'Error',
        description: 'An error occurred while canceling the approval. Please try again later.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } 
  };

  // Function to handle rejection confirmation
  const handleRejectConfirm = async () => {
    // Check if both rejection reason and note are filled out
    if (!rejectionReason.trim() || !rejectionNote.trim()) {
      toast({
        title: 'Error',
        description: 'Please provide both rejection reason and note.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    setIsLoading(true); 
    const payload: Payload = {
      process: 'EXPReject',
      id: rowId,
      rejectionReason: rejectionReason,
      rejectionComment: rejectionNote,
      email: userEmail
    };
    try {
      // Call the API to handle rejection
      const response = await WaitlistAPI(payload);
      toast({
        title: 'Rejection Confirmed',
        description: 'The rejection has been confirmed successfully.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      fetchData();
      closeDialog();
      setIsLoading(false); 
    } catch (error) {
      toast({
        title: 'Error',
        description: 'An error occurred while confirming rejection. Please try again later.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Function to open the drawer and set the selected row data
  const openDrawer = (rowData: TableRow) => {
    setSelectedRowData(rowData);
    setIsDrawerOpen(true);
  };

  // Function to close the drawer
  const closeDrawer = () => {
    setSelectedRowData(null);
    setIsDrawerOpen(false);
  };

//function to parse and compare account IDs (Perferred Accounts being sorted 147-149)
const parseAndCompareAccountIds = (a: string, b: string): number => {
  const extractId = (id: string): { number: number; text: string } => {
    const [numberPart, ...rest] = id.split(' - ');
    return {
      number: parseInt(numberPart, 10),
      text: rest.join(' - ')
    };
  };
  const aId = extractId(a);
  const bId = extractId(b);
  if (aId.number < bId.number) return -1;
  if (aId.number > bId.number) return 1;
  // If the number is the same, then sort by the rest of the ID text
  return aId.text.localeCompare(bId.text);
};

//This is the main Cost Center section that displays all the tables by cost center (preferred account)
const renderRightSectionTables = () => {
  // Sort the account entries by the custom comparator function
  const sortedEntries = Object.entries(rightSectionTables)
    .filter(([accountId, rows]) => {
      const hasNonEmptyPreferredAccount = rows.some(row => row.data.PreferredAccount !== 'None');
      const hasNonCompletedOrRejectedRows = rows.some(row => row.data.Status !== 'Completed' && row.data.Status !== 'Rejected');
      return accountId !== 'None' && hasNonEmptyPreferredAccount && hasNonCompletedOrRejectedRows;
    })
    .sort((a, b) => parseAndCompareAccountIds(a[0], b[0]));
    // map over the sorted entries
    return sortedEntries.map(([accountId, rows]) => {
      const filteredRows = rows.filter(row => row.data.Status !== 'Completed' && row.data.Status !== 'Rejected');
      return (
        <Box
          mb="4"
          p="4"
          shadow="md"
          borderRadius="xl"
          bg="#F5F5F5"
          _dark={{ bg: "gray.200" }}
          key={accountId}
          width="99%"

        >
          <Heading size="sm" mb="2" borderBottom="2px" borderColor="blue.700">
            {accountId}
          </Heading>
          <Droppable droppableId={accountId} key={accountId}>
            {(provided, snapshot) => (
              <Table colorScheme="blue" width="100%">
                <Tbody
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {filteredRows.map((row, index) => (
                    <Draggable key={row.id} draggableId={row.id} index={index}>
                      {(provided, snapshot) => (
                        <Tr
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{
                            ...provided.draggableProps.style,
                            backgroundColor: snapshot.isDragging ? 'lightgray' : row.data.Status === 'Approved' ? '#E0EEE0' : 'inherit',
                          }}
                          width="100%"
                        >
                          <Td width="5%">{index + 1}</Td>
                          <Td width="25%">{toCamelCase(row.data.DriverName)}</Td>
                          <Td width="20%">{row.data.DriverCode}</Td>
                          <Td width="20%">
                            {row.data.Status === 'Approved' ? (
                              <>
                                 {/* Complete button */}
                                <IconButton
                                  aria-label="complete"
                                  icon={<FaCheck />}
                                  size="sm"
                                  variant="outline"
                                  colorScheme="green"
                                  onClick={() => openDialog('complete', row.id)}
                                  mr="2"
                                />
                                   {/* Cancel button */}
                                <IconButton
                                  aria-label="cancel approval"
                                  icon={<FaTimes />}
                                  size="sm"
                                  variant="outline"
                                  colorScheme="red"
                                  onClick={() => openDialog('cancel', row.id)}
                                />
                              </>
                            ) : (
                              <>
                                 {/* Approve button */}
                                <IconButton
                                  aria-label="thumbs up"
                                  icon={<FaThumbsUp />}
                                  size="sm"
                                  variant="outline"
                                  colorScheme="green"
                                  mr="2"
                                  onClick={() => openDialog('approve', row.id)}
                                />
                                {/* Reject button */}
                                <IconButton
                                  aria-label="thumbs down"
                                  icon={<FaThumbsDown />}
                                  size="sm"
                                  variant="outline"
                                  colorScheme="red"
                                  mr="2"
                                  onClick={() => openDialog('reject', row.id)}
                                />
                                   {/* Move back button */}
                                <IconButton
                                  aria-label="move back"
                                  icon={<ArrowBackIcon />}
                                  size="sm"
                                  variant="outline"
                                  colorScheme="blue"
                                  mr="2"
                                  onClick={() => openDialog('moveback', row.id)}
                                />
                                {/* Details button */}
                                <IconButton
                                  aria-label="details"
                                  icon={<FaInfoCircle />}
                                  size="sm"
                                  variant="outline"
                                  colorScheme="black"
                                  onClick={() => openDrawer(row)}
                                />
                              </>
                            )}
                          </Td>
                        </Tr>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </Tbody>
              </Table>
            )}
          </Droppable>
        </Box>
      );
    });
  };

  return (
    <>
      {/* Loading Spinner */}
      {isLoading && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            zIndex: 9999,
          }}
        >
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
          />
        </div>
      )}
   {/* Main Container  */}
      <Center height="calc(100vh - 55px)" width="100vw" bg="blackAlpha.100">
        <Flex direction={{ base: "column", md: "row" }} p="5" wrap="wrap" align="stretch" height="100%" width="100%">
          <Box flex="1" bg="whiteAlpha.700" borderRadius="lg" shadow="lg" p="3" height="100%">
            <Heading
              mb="4"
              fontSize={{ base: "xl", md: "xl" }}
              fontWeight="normal"
              fontFamily="sans-serif"
              color="#483C32"
              bg="blackAlpha.100"
              p={2}
              borderRadius="md"
              textAlign="center"
            >
              First Available
            </Heading>
            <Text fontSize="sm" color="gray.600" m="2">
              Drivers wanting to switch to EXP and are willing to go to the first available Cost Center.
            </Text>
            {/* Left Section for displaying first available drivers with no preferred account  */}
            <EXPfirstAvailable
              data={Object.values(rightSectionTables).flat() as TableRow[]}
              fetchData={fetchData}
              email={userEmail}
              name={userName}

            />
          </Box>
          <Box flex="2" display="flex" flexDirection="column" gap="30" height="100%" overflowY="auto" ml={{ base: "0", md: "10px" }}>
            {/* container for the right section tables displaying each cost center*/}
            <Box bg="white" borderRadius="lg" p="3" height="100%" overflowY="auto" position="relative">
              <Heading
                w="99%"
                mb="2"
                fontSize={{ base: "xl", md: "xl" }}
                fontWeight="normal"
                fontFamily="sans-serif"
                color="#483C32"
                bg="blackAlpha.100"
                p={2}
                borderRadius="md"
                textAlign="center"
              >
                Cost Center
              </Heading>
              <Box maxHeight="calc(100% - 56px)" overflowY="auto" borderRadius="lg">
                <DragDropContext onDragEnd={onDragEnd}>
                  {renderRightSectionTables()}
                </DragDropContext>
              </Box>
            </Box>
          </Box>
        </Flex>
        {/* Confirm Dialog component for displaying the alert types  */}
        <ConfirmDialog
          isOpen={isDialogOpen}
          onClose={closeDialog}
          onConfirm={confirmType === 'complete' ? handleCompleteConfirm :
            confirmType === 'reject' ? handleRejectConfirm :
              confirmType === 'moveback' ? handleMoveBack :
                confirmType === 'cancel' ? handleCancelApproval :
                  handleConfirm}
          confirmType={confirmType}
          rejectionReason={rejectionReason}
          setRejectionReason={setRejectionReason}
          rejectionNote={rejectionNote}
          setRejectionNote={setRejectionNote}
        />
        {/* Drawer container */}
        <DriverDetailDrawer
          isOpen={isDrawerOpen}
          onClose={closeDrawer}
          driverData={selectedRowData}
          fetchData={fetchData}
          rowData={selectedRowData}
          email={userEmail}
          name={userName}
        />
      </Center>
    </>
  );


};

export default EXPwaitlist;
