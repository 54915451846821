import React, { useState, useEffect } from 'react';
import { Box, Table, Thead, Th, Tbody, Tr, Td, Text } from '@chakra-ui/react';
import DriverDetailDrawer from './EXPDriverDetailDrawer';
// At the top of EXPfirstAvailable.tsx
import { TableRow } from './EXPwaitlist';

interface Props {
  data: TableRow[];
  fetchData: () => void;
  email: string; 
  name: string;
}


const EXPfirstAvailable: React.FC<Props> = ({ data, fetchData, email, name }) => {
  const [filteredData, setFilteredData] = useState<TableRow[]>([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedDriver, setSelectedDriver] = useState<TableRow | null>(null);

  const toCamelCase = (str: string) => {
    return str.toLowerCase().replace(/(^|\s)\S/g, (match) => match.toUpperCase());
  };



  useEffect(() => {
    const filtered = data.filter((item) =>
      item.data.Status === "Pending" &&
      item.data.PreferredAccount === 'None' &&
      item.data.FirstAvailable === 'true'
    ).map(driver => ({
      ...driver,
      data: {
        ...driver.data,
        FirstAvailable: "true"
      }
    }));

    setFilteredData(filtered);
  }, [data]);

  const openDrawer = (driver: TableRow) => {
    setSelectedDriver(driver);
    setIsDrawerOpen(true);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };

  return (
    <Box p="4" shadow="base" borderRadius="lg" overflowY="auto" bg="#F5F5F5">
      {filteredData.length === 0 ? (
        <Text textAlign="center" color="gray.600">No drivers are in 'First Available' status at this time.</Text>
      ) : (
        <Table colorScheme="blue">
          <Tbody>
            {filteredData.map((driver, index) => (
              <Tr key={index} onClick={() => openDrawer(driver)} cursor="pointer">
                <Td>{driver.data.DriverCode}</Td>
                <Td>{toCamelCase(driver.data.DriverName)}</Td>
                <Td>{driver.data.StatusChangeType}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      )}
      {selectedDriver && (
     <DriverDetailDrawer
     isOpen={isDrawerOpen}
     onClose={closeDrawer}
     driverData={selectedDriver}
     fetchData={fetchData}
     rowData={null}
     email={email} 
     name={name}
   />
   
      )}
    </Box>
  );
};

export default EXPfirstAvailable;
