import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Image,
  Text,
  Button,
  Avatar,
  IconButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  Portal,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Textarea,
  useToast,
  Heading,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { ChatIcon } from "@chakra-ui/icons";


interface TopBarProps {
  userName: string;
  userPhoto: string | null;
  userEmail: string | null;

}



const TopBar: React.FC<TopBarProps> = ({
  userName,
  userPhoto,
  userEmail,
}) => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [submittingFeedback, setSubmittingFeedback] = useState(false);
  const [feedback, setFeedback] = useState("");
  const navigate = useNavigate();
  const { instance } = useMsal();


 
  return (
    <Flex
      align="center"
      p={2}
      height="55px"
      css={{
        backdropFilter: "blur(10px)",
      }}
      paddingRight={5}
      background="whiteAlpha.800"
    >
      <Box
      
        style={{ cursor: "pointer" }}
        display="flex"
        alignItems="center"
        ml="20px"
      >
        <Image src="/logo.png" alt="Logo" maxH={6} />
      </Box>
      <Heading ml="10" size="md" fontWeight="normal" color="blackAlpha.900">Expedited Waitlist</Heading>

      <Flex marginLeft="auto" alignItems="center">
        
        <Popover>
          <PopoverTrigger>
            <IconButton
              aria-label="Notifications"
              fontSize="xl"
              variant="solid"
              size="md"
              icon={
                <Avatar
                  name={userName}
                  src={userPhoto || undefined}
                  borderRadius="full"
                  boxSize="40px"
                  objectFit="cover"
                />
              }
              marginRight="3"
              borderRadius="full"
              colorScheme="gray"
            />
          </PopoverTrigger>
          <Portal>
            <PopoverContent
              color="gray.800"
              bg="white"
              margin="1"
              marginTop="2"
              padding="2"
              boxShadow="2xl"
              borderColor="white"
            >
              <PopoverHeader
                pt={4}
                fontWeight="medium"
                border="1"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Text color="blackAlpha.800">{userName}</Text>
              </PopoverHeader>
              <PopoverBody>
                <Button
              
                  variant="ghost"
                  size="md"
                  width="100%"
                  textColor="gray.500"
                  fontWeight="medium"
                  justifyContent="flex-start"
                >
                  Sign out
                </Button>
              </PopoverBody>
            </PopoverContent>
          </Portal>
        </Popover>
      </Flex>
      <Modal isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Feedback</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Textarea
              placeholder="Please provide your feedback here"
              height="200px"
              value={feedback}
        
            />
          </ModalBody>

          <ModalFooter>
            <Button
              variant="solid"
          
              isLoading={submittingFeedback}
              loadingText="Submitting..."
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default TopBar;
